import React, { useEffect, useRef } from "react";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import "./Services.css";

const GraceService = () => {
    const navigate = useNavigate();
    const sectionRef = useRef(null);

    useEffect(() => {
        const handleAnimation = () => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("in-view");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            const leftColumn = sectionRef.current.querySelector(".col-md-6.left");
            const rightColumn = sectionRef.current.querySelector(".col-md-6.right");

            // Only apply animation logic if the screen width is larger than 800px
            if (window.innerWidth > 800) {
                if (leftColumn) observer.observe(leftColumn);
                if (rightColumn) observer.observe(rightColumn);
            } else {
                // Ensure elements are visible without animation
                if (leftColumn) {
                    leftColumn.style.opacity = "1";
                    leftColumn.style.transform = "none";
                }
                if (rightColumn) {
                    rightColumn.style.opacity = "1";
                    rightColumn.style.transform = "none";
                }
            }

            return () => {
                if (leftColumn) observer.unobserve(leftColumn);
                if (rightColumn) observer.unobserve(rightColumn);
            };
        };

        handleAnimation();

        // Reset scroll position to top and re-trigger animations
        window.scrollTo(0, 0);
        const columns = sectionRef.current.querySelectorAll(".col-md-6");
        columns.forEach((column) => column.classList.remove("in-view"));

        // Add event listener to trigger animations when scrolled back to top
        window.addEventListener("scroll", handleAnimation);

        return () => {
            window.removeEventListener("scroll", handleAnimation);
        };
    }, []);

    return (
        <section className="grace_service_section" id="industries" ref={sectionRef}>
            <div className="container">
                <h1>Providing more than services</h1>
                <div className="row">
                    <div className="col-md-6 left">
                        <h4 className="industry_h4">industry sectors</h4>
                    </div>
                    <div className="col-md-6 right">
                        <div className="grace-service_div">
                            <h4 onClick={() => navigate("/graceassociates")}>
                                Grace Associate <GoArrowRight />
                            </h4>
                            <p>
                                Grace Associate, your trusted partner in statutory and compliance services. Established in 2009, we have built a reputation for delivering expert solutions to businesses across various sectors. With over 30 years of experience, we are dedicated to helping our clients navigate the complexities of statutory obligations with ease and confidence.
                            </p>
                        </div>
                        <div className="grace-service_div">
                            <h4 onClick={() => navigate("/gracepaperbags")}>
                                Grace Paper Bags <GoArrowRight />
                            </h4>
                            <p>
                                Grace paper bags was established in 2009, We Grace Traders are providing the best quality of all types of paper bags. We are engaged in offering high quality range of Paper Bag to our esteemed customers. We are the supplier, distributor, wholesaler and exporter of Designer Paper Bags.
                            </p>
                        </div>
                        <div className="grace-service_div">
                            <h4 onClick={() => navigate("/eurocleantech")}>
                                Euro Clean Tech <GoArrowRight />
                            </h4>
                            <p>
                                Euro Clean Tech is bringing the latest and innovation cleaning solution for fast changing and highly demanding corporate environment. The ultimate mission of Euro Clean Tech is to keep the nation "clean & healthy" & "Environment friendly".
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GraceService;