import React, { useEffect } from 'react';
import "./EuroCleanProcess.css";

const EuroCleanProcess = () => {
    useEffect(() => {
        const elements = document.querySelectorAll('.euro_clean_process_div, .euro_clean_process_div_left');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show'); 
                } else {
                    entry.target.classList.remove('show'); 
                }
            });
        }, { threshold: 0.5 }); 

        elements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            elements.forEach((element) => {
                observer.unobserve(element); 
            });
        };
    }, []);
    return (
        <>
            <section className="euro_clean_process">
                <div className="container">
                    <h2 className="bordered_text"><span>Our Process</span></h2>
                    <p className="euro_clean_process_p">The 9 stage cleaning process has been developed through research and development to arrive at a full proof process that involves through state-of-the-art imported equipment and proprietary antibacterial agents that are safe, effective and Eco-friendly initially we connected electrical gadgets as per the requirement after conducting checks for electrical safety then the actual cleaning process begins.</p>
                    <div className="row right_row">
                        <div className="col-md-8">
                            <div className="euro_clean_process_div">
                                <img src={"/assets/mechnised.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>Mechanized dewatering of the tanks to be<br />cleaned</h4>
                                    <p>A pump is used to remove water. Manhole and surroundings are cleaned off dirt, mud and algae.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row left_row">
                        <div className='col-md-4'></div>
                        <div className="col-md-8">
                            <div className="euro_clean_process_div_left">
                                <img src={"/assets/sludge-removal-500x500.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>Sludge Removal(By mechanized pumps)</h4>
                                    <p>A sluged pumps used to dispose off the dirty water & sludge.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row right_row">
                        <div className="col-md-8">
                            <div className="euro_clean_process_div">
                                <img src={"/assets/Hi.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>High Pressure Cleaning</h4>
                                    <p>Floor, walls & ceilins of the tanks are cleaned using special high pressure jet.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row left_row">
                        <div className='col-md-4'></div>
                        <div className="col-md-8">
                            <div className="euro_clean_process_div_left">
                                <img src={"/assets/Vacum.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>Vacum Cleaning</h4>
                                    <p>A vacum pump is used to remove sludge after cleaning the tank floor with high pressure jet. An Industrial pump is then used to clean the remaining dirt.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row right_row">
                        <div className="col-md-8">
                            <div className="euro_clean_process_div">
                                <img src={"/assets/anti.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>Anti-Bacterial Spray</h4>
                                    <p>The walls & cleaning are made totally sterile from bacteria attack using natural antibacterial agents.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row left_row">
                        <div className='col-md-4'></div>
                        <div className="col-md-8">
                            <div className="euro_clean_process_div_left">
                                <img src={"/assets/uv.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>UV Radiation</h4>
                                    <p>A specially developed UV radiator is used to kill suspended or floating bacteria remaining in the tank.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row right_row">
                        <div className="col-md-8">
                            <div className="euro_clean_process_div">
                                <img src={"/assets/house (2).webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>House Keeping Services</h4>
                                    <p>Professional and reliable housekeeping services tailored to keep your space spotless and organized.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row left_row">
                        <div className='col-md-4'></div>
                        <div className="col-md-8">
                            <div className="euro_clean_process_div_left">
                                <img src={"/assets/house (1).webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>House Painting & Cleaning Work</h4>
                                    <p>Expert house painting and cleaning services to refresh and revitalize your home inside and out.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                <div className="horizontal-dashed-arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row right_row">
                        <div className="col-md-8">
                            <div className="euro_clean_process_div_last">
                                <img src={"/assets/house.webp"} alt="img" />
                                <div className="euro_clean_process_div_content">
                                    <h4>House Shifting, Packing & Forwarding</h4>
                                    <p>Professional and reliable housekeeping services tailored to keep your space spotless and organized.</p>
                                </div>
                                {/* Horizontal dashed line with arrow */}
                                {/* <div className="horizontal-dashed-arrow"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EuroCleanProcess;