import React from "react";
import GraceAssociateService from "./Service";
import GraceChooseUs from "./WhyChooseUs";
import GraceAssociateAbout from "./AboutUs";
import GetInTouch from "../GraceHome/GetInTouch";
import GraceFooter from "../GraceHome/Footer";
import GraceAssociateBanner from "./GraceBanner";
import GraceAssociateHeader from "./Header";
import { FaWhatsapp } from "react-icons/fa";
import AssociateGetInTouch from "./GetInTouch";

const GraceAssociate = () => {
    const whatsappNumber = "9655214330";
    const preOccupiedMessage = "Hello GraceAssociates!\n I would like to know more about your services.";
    // const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(preOccupiedMessage)}`;
    const whatsappLink = `https://wa.me/c/919585833484`;
    return (
        <>
            <GraceAssociateHeader />
            <GraceAssociateBanner />
            <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-chat-icon"
            >
                <FaWhatsapp />
            </a>
            <GraceAssociateService />
            <GraceChooseUs />
            <GraceAssociateAbout />
            {/* <GetInTouch /> */}
            <AssociateGetInTouch />
            <GraceFooter />
        </>
    )
}

export default GraceAssociate;