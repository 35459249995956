import React from "react";
import EuroCleanTechBanner from "./EuroCleanTechBanner";
import EuroCleanProcess from "./EuroCleanProcess";
import EuroAboutUs from "./EuroAboutUs";
import GetInTouch from "../GraceHome/GetInTouch";
import GraceFooter from "../GraceHome/Footer";
import EuroHeader from "./EuroHeader";
import EuroServices from "./EuroServices";
import { FaWhatsapp } from "react-icons/fa";
import EuroGetInTouch from "./GetInTouch";

const EuroCleanTech = () => {
    const whatsappNumber = "9655214330";
    const preOccupiedMessage = "Hello GraceAssociates!\n I would like to know more about your services.";
    // const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(preOccupiedMessage)}`;
    const whatsappLink = `https://wa.me/c/919585833484`;
    return (
        <>
            <EuroHeader />
            <EuroCleanTechBanner />
            <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-chat-icon"
            >
                <FaWhatsapp />
            </a>
            <EuroServices />
            <EuroCleanProcess />
            <EuroAboutUs />
            {/* <GetInTouch /> */}
            <EuroGetInTouch />
            <GraceFooter />
        </>
    )
};

export default EuroCleanTech;